<template>
  <div>
    <CRow>
      <CCol sm="6" md="4">
        <CCard>
          <CCardHeader>
            <strong>{{ course.title }}</strong>
          </CCardHeader>
          <CCardBody>
            <ul>
              <li v-for="lesson in lessons" :key="lesson.id">
                {{ lesson.title }}
              </li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6" md="8">
        <CForm @submit.prevent="submit">
          <CCard>
            <CCardHeader>
              Agregar nuevo quiz
            </CCardHeader>
            <CCardBody>
              <CInput
                type="text"
                label="Título del quiz"
                v-model="quiz.title"
                required
              />

              <CInput
                type="number"
                label="Puntaje mínimo / 100"
                v-model="quiz.min_score"
                required
              />

              <CInput
                type="number"
                label="Tiempo máximo en minutos"
                v-model="quiz.max_time"
              />
              <div class="row mt-5 mb-4 border p-3" v-for="(question, index) in questions" :key="index">
                <div class="col-md-12">
                  <label for="">Pregunta {{ index + 1 }}</label>
                  <CButton class="float-right" size="sm" color="danger" @click="deleteQuestion(index)">
                    x
                  </CButton>
                  <CInput
                    v-model="question.question"
                    type="text"
                  />
                  <div class="row" v-for="(option, option_index) in question.options" :key="option_index">
                    <div class="col-md-2">
                        Respuesta {{ option_index + 1}}
                    </div>
                    <div class="col-md-8">
                      <CInput
                        v-model="option.option"
                      />
                    </div>
                    <div class="col-md-2">
                      <input title="Correcta" type="checkbox" v-model="option.correct" :value="1" />
                      <CButton class="float-right" size="sm" color="danger" @click="deleteOption(index, option_index)">
                        x
                      </CButton>
                    </div>
                  </div>
                  
                  <CButton
                    color="info"
                    @click="addOption(index)"
                    class="float-right"
                     size="sm"
                  >
                    Nueva Respuesta
                  </CButton>
                  
                </div>
              </div>

              <CButton
                color="info"
                @click="addQuestion"
                size="sm"
              >
                Nueva pregunta
              </CButton>

            </CCardBody>
            <CCardFooter>
              <CButton 
                type="submit"
                color="success"
                class="float-right"
              >
                Guardar quiz
              </CButton>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Quiz',
  data: function () {
    return {
        course: {},
        lessons: [],
        active_index: 0,
        questions: [],
        quiz: {
          title: '',
          min_score: 70,
          course_id : this.$route.params.id,
          max_time: null,
        },
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted() {
      const token = this.$cookies.get('login_token')
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.course = response.data.data;
        })

      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id + '/lessons', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.lessons = response.data.data;
        })
  },
  methods: {
    addQuestion() {
      let question = {
        question: '',
        options: []
      }
      this.questions.push(question)
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1)
    },
    addOption(index) {
      this.questions[index].options.push({
        option: '',
        correct: 0
      })
    },
    deleteOption(question_index, index) {
      this.questions[question_index].options.splice(index, 1)
    },
    submit() {
      const token = this.$cookies.get('login_token')
      console.log(this.form);
      const formData = {
        quiz: this.quiz,
        questions : this.questions
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL+'/api/quiz', formData, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.$router.push('/course/' + this.$route.params.id)
        })
    }
  }
}
</script>
